import { TranslateService } from '../../../services/translate/translate.service';
import { Injectable } from '@angular/core';

export type AccessibilityGuidelineType = 'wcag' | 'qualweb'
export type AccessibilityGuidelineInfo = {
    name: string,
    value: string,
    isMobile?: boolean,
    type: AccessibilityGuidelineType,
}
export type AccessibilityGuidelineVersionMap = {
    [key in AccessibilityGuidelineType]: number;
};

@Injectable({
    providedIn: 'root',
})
export class AccessibilityGuidelineInfoService {
    readonly guidelines: AccessibilityGuidelineInfo[] = [];
    readonly guidelineVersionsMap: AccessibilityGuidelineVersionMap = {
        wcag: 6,
        qualweb: 1,
    };

    guidelineValueNameMap: Record<string, string> = {};

    constructor (private translateService: TranslateService) {
        // @sonar-ignore
        this.guidelines = [
            {
                name: this.translateService.getString('WCAG 2.0 (Level A)'),
                value: 'WCAG2-A',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.0 (Level AA)'),
                value: 'WCAG2-AA',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.0 (Level AAA)'),
                value: 'WCAG2-AAA',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.1 (Level A)'),
                value: 'WCAG21-A',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.1 (Level AA)'),
                value: 'WCAG21-AA',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.1 (Level AAA)'),
                value: 'WCAG21-AAA',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.1 (Level AA) Mobile'),
                value: 'WCAG21-AA_mobile',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.1 (Level AAA) Mobile'),
                value: 'WCAG21-AAA_mobile',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.2 (Level A)'),
                value: 'WCAG22-A',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.2 (Level AA)'),
                value: 'WCAG22-AA',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.2 (Level AAA)'),
                value: 'WCAG22-AAA',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.2 (Level A) Mobile'),
                value: 'WCAG22-A_mobile',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.2 (Level AA) Mobile'),
                value: 'WCAG22-AA_mobile',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('WCAG 2.2 (Level AAA) Mobile'),
                value: 'WCAG22-AAA_mobile',
                type: 'wcag',
            },
            {
                name: this.translateService.getString('QualWeb (Level A)'),
                value: 'QUALWEB-A',
                type: 'qualweb',
            },
            {
                name: this.translateService.getString('QualWeb (Level AA)'),
                value: 'QUALWEB-AA',
                type: 'qualweb',
            },
        ];
        // @end
        for (const guideline of this.guidelines) {
            this.guidelineValueNameMap[guideline.value] = guideline.name;
        }
    }

    getAccessibilityTypeFromGuidelineValue (value: string): AccessibilityGuidelineType | null {
        if (value.startsWith('WCAG')) {
            return 'wcag';
        }
        if (value.startsWith('QUALWEB')) {
            return 'qualweb';
        }

        return null;
    }

}
